<template>
  <el-card shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="code" placeholder="内部归还单号" clearable style="width: 210px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="lenovoCode" placeholder="新的联想单号" clearable style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changeCode" :loading="loading">修改</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "ChangeLenovoCode",
  data() {
    return {
      code: '',
      lenovoCode: '',
      loading: false
    }
  },
  methods: {
    changeCode() {
      if (!this.code) {
        return this.$message.error('内部归还单号不能为空！');
      }
      if (!this.lenovoCode) {
        return this.$message.error('联想归还单号不能为空！');
      }

      this.$confirm('确定修改吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.post('returnOrder/changeLenovoCode', {
          code: this.code,
          lenovoCode: this.lenovoCode,
        }).then(response => {
          this.loading = false;
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }

          this.code = ''
          this.lenovoCode = ''
          return this.$message.success('操作成功');

        }, error => {
          this.loading = false;
          return this.$message.error('操作失败，' + error.message);
        });
      })

    }
  }
}
</script>

<style scoped>

</style>